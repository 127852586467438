import * as F from "fp-ts/function";
import { Reducer, dictionary, PaymentStateType } from "common";
import { CheckoutState, CheckoutStateType } from "../state";
import { CheckoutAction, ApplicationActionType } from "../../../applicationState/actions";

export const checkoutReducer: Reducer<CheckoutState, CheckoutAction> = (state, action) => {
	switch (action.type) {
		case ApplicationActionType.CHECKOUT_LOAD_FROM_LOCAL_STORAGE:
			return {
				...state,
				type: CheckoutStateType.FETCHING,
			};
		case ApplicationActionType.CHECKOUT_SET_REQUEST:
			return {
				...state,
				type: CheckoutStateType.DEFAULT,
				request: {
					...state.request,
					...action.request,
					products: {
						...state.request.products,
						...(action.request.products || {}),
					},
				},
			};
		case ApplicationActionType.CHECKOUT_SET_LOCATION:
			return {
				...state,
				request: {
					...state.request,
					locationId: action.locationId,
					products: {},
				},
			};
		case ApplicationActionType.CHECKOUT_SET_NAME:
			return {
				...state,
				request: {
					...state.request,
					name: action.name,
				},
			};
		case ApplicationActionType.CHECKOUT_SET_EMAIL:
			return {
				...state,
				request: {
					...state.request,
					email: action.email,
				},
			};
		case ApplicationActionType.CHECKOUT_SET_PHONE:
			return {
				...state,
				request: {
					...state.request,
					phone: action.phone,
				},
			};
		case ApplicationActionType.CHECKOUT_SET_DATE:
			return {
				...state,
				request: {
					...state.request,
					date: action.date,
				},
			};
		case ApplicationActionType.CHECKOUT_SET_TIME:
			return {
				...state,
				request: {
					...state.request,
					time: action.time,
				},
			};
		case ApplicationActionType.CHECKOUT_SET_MESSAGE:
			return {
				...state,
				request: {
					...state.request,
					message: action.message,
				},
			};
		case ApplicationActionType.CHECKOUT_EXTEND_MESSAGE:
			return {
				...state,
				request: {
					...state.request,
					message: (state.request.message || "") + action.message,
				},
			};
		case ApplicationActionType.CHECKOUT_TOGGLE_PRIVACY:
			return {
				...state,
				request: {
					...state.request,
					privacyCheck: !state.request.privacyCheck,
				},
			};
		case ApplicationActionType.CHECKOUT_SET_PRODUCT: {
			const previousState = state.request.products[action.productId] || 0;
			const resolvedState = previousState < 0 ? 0 : previousState;

			const isNotPositiveAction = action.amount <= 0;
			const newAmount = isNotPositiveAction ? resolvedState : action.amount;

			return {
				...state,
				request: {
					...state.request,
					products: {
						...state.request.products,
						[action.productId]: newAmount,
					},
				},
			};
		}
		case ApplicationActionType.CHECKOUT_INC_PRODUCT: {
			const previousState = state.request.products[action.productId] || 0;
			const resolvedState = previousState >= 0 ? previousState : 0;
			const newProductAmount = resolvedState + 1;
			return {
				...state,
				request: {
					...state.request,
					products: {
						...state.request.products,
						[action.productId]: newProductAmount,
					},
				},
			};
		}
		case ApplicationActionType.CHECKOUT_DEC_PRODUCT: {
			const stateLessThenZero = (state.request.products[action.productId] || 0) <= 0;
			const newProductAmount = (() => {
				if (stateLessThenZero) {
					return 0;
				} else {
					return (state.request.products[action.productId] || 1) - 1;
				}
			})();
			return {
				...state,
				request: {
					...state.request,
					products: {
						...state.request.products,
						[action.productId]: newProductAmount,
					},
				},
			};
		}
		case ApplicationActionType.CHECKOUT_REMOVE_PRODUCT:
			return {
				...state,
				request: {
					...state.request,
					products: F.pipe(state.request.products, dictionary.removeKeyValuePair(action.productId)),
				},
			};
		case ApplicationActionType.CHECKOUT_SET_PAYMENT_METHOD:
			return {
				...state,
				request: {
					...state.request,
					paymentMethod: action.method,
				},
			};
		case ApplicationActionType.CHECKOUT_SET_PAYMENT_STATE:
			return {
				...state,
				request: {
					...state.request,
					paymentState: action.state,
				},
			};
		case ApplicationActionType.CHECKOUT_SET_VALIDATING:
			return {
				...state,
				type: CheckoutStateType.VALIDATING,
				request: {
					...state.request,
					products: Object.fromEntries(
						Object.entries(state.request.products).filter(([id, amount]) => !!amount && amount > 0)
					),
				},
			};
		case ApplicationActionType.CHECKOUT_SET_SUBMITTING:
			return {
				...state,
				type: CheckoutStateType.SUBMITTING,
			};
		case ApplicationActionType.CHECKOUT_ABORT_SUBMITTING:
			return {
				...state,
				type: CheckoutStateType.DEFAULT,
			};
		case ApplicationActionType.CHECKOUT_SET_SUBMITTING_ERROR:
			return {
				...state,
				type: CheckoutStateType.SUBMITTING_ERROR,
				error: action.error,
			};
		case ApplicationActionType.CHECKOUT_SET_FINISHED:
			return {
				type: CheckoutStateType.FINISHED,
				request: {
					paymentState: PaymentStateType.PENDING,
					locationId: state.request.locationId,
					name: state.request.name,
					email: state.request.email,
					phone: state.request.phone,
					products: {},
				},
			};
		case ApplicationActionType.CHECKOUT_RESET:
			return {
				...state,
				type: CheckoutStateType.DEFAULT,
			};
		default:
			return state;
	}
};
